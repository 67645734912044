import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Container as ContainerBase } from "components/misc/Layouts.js";
import logo from "../../images/logo.svg";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";
import { LogoBackground, LogoImg, LogoText } from "components/misc/Logo";

const Container = tw(ContainerBase)`bg-primary-900 text-gray-100 -mx-8 -mb-8`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`;
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`;

export default ({
  title = "title",
  copyright = "copyright",
  socialLinks = [
    { href: "https://facebook.com", icon: <FacebookIcon /> },
    { href: "https://twitter.com", icon: <TwitterIcon /> },
    { href: "https://youtube.com", icon: <YoutubeIcon /> },
  ],
  navLinks = [
    { href: "#", text: "Home" },
    { href: "#", text: "About" },
    { href: "#", text: "Contact Us" },
    { href: "#", text: "Blog" },
    { href: "#", text: "Reviews" },
  ],
}) => {
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <LogoBackground>
              <LogoImg src={logo} />
            </LogoBackground>
            <LogoText>{title}</LogoText>
          </LogoContainer>
          <LinksContainer>
            {navLinks &&
              navLinks.map((nav, index) => (
                <Link href={nav.href} key={index}>
                  {nav.text}
                </Link>
              ))}
          </LinksContainer>
          {socialLinks && (
            <SocialLinksContainer>
              {socialLinks.map((item, index) => (
                <SocialLink href={item.href} key={index}>
                  {item.icon}
                </SocialLink>
              ))}
            </SocialLinksContainer>
          )}
          <CopyrightText>{copyright}</CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};
