// hooks
import useLocales from "hooks/useLocales";

// helpers
import TwoColWithTextAndPreviewCard from "components/features/TwoColWithTextAndPreviewCard";

// sections
import BaseSolutionPage from "./BaseSolutionPage";
import Markdown from "components/markdown/Markdown";

export default () => {
  const { t, currentLang } = useLocales();

  const headingText = t("jmtEnvironmentMonitoring.title");

  return (
    <BaseSolutionPage headingText={headingText} category="iotSolutions">
      <TwoColWithTextAndPreviewCard
        textContent={<Markdown children={getContent(currentLang)} />}
        btnViewText={t("buttons.view")}
        card={null}
      />
    </BaseSolutionPage>
  );
};

function getContent(currentLang) {
  if (currentLang?.value === "en") return enContent;
  return viContent;
}

const enContent = `
TBU
`;

const viContent = `
### Hệ thống giám sát môi trường ngoài trời

*   Đặt các trạm quan trắc môi trường ngoài trời: nhiệt độ và độ ẩm, ánh sáng,CO2, Bụi PM2.5/PM10, tiếng ồn.
*   Hiển thị thông tin trên màn hình led.
*   Chức năng quảng cáo trên màn hình led.
    
### Hệ thống giám sát môi trường phòng máy chủ

*   Đặt các thiết bị cảm biến môi trường trong nhà: nhiệt độ, độ ẩm, rò rỉ nước, báo cháy, mất nguồn điện, mở cửa, tốc độ gió...
*   Cảnh báo khi có các sự cố xảy ra.
    
### Hệ thống giám sát môi trường trong nhà kính

*   Đặt các thiết bị cảm biến môi trường trong nhà: nhiệt độ, độ ẩm, ánh sáng, CO2 carbon dioxide, độ ẩm của đất, pH đất, hướng và tốc độ gió, đo lượng mưa.
*   Cài đặt các cảnh báo.
*   Tích hợp với hệ thống tưới nước thông minh.
*   Tích hợp với hệ thống chiếu sáng thông minh.
`;
