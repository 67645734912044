import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import styled from "styled-components";
import tw from "twin.macro";

const MarkdownStyle = styled.div`
  ${tw`text-lg text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h3 {
    ${tw`text-3xl font-bold mt-6`}
  }
  h4 {
    ${tw`text-2xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside mt-2 ml-3`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
    ul {
      ${tw`list-decimal list-inside mt-2 ml-3`}
    }
  }
`;

export default function Markdown(props) {
  return (
    <MarkdownStyle>
      <ReactMarkdown rehypePlugins={[rehypeRaw]} {...props} />
    </MarkdownStyle>
  );
}
