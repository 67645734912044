// hooks
import useLocales from "hooks/useLocales";

// helpers
import TwoColWithTextAndPreviewCard from "components/features/TwoColWithTextAndPreviewCard";

// sections
import BaseSolutionPage from "./BaseSolutionPage";
import Markdown from "components/markdown/Markdown";

export default () => {
  const { t, currentLang } = useLocales();

  const headingText = t("jmtCameraAi.title");

  return (
    <BaseSolutionPage headingText={headingText} category="iotSolutions">
      <TwoColWithTextAndPreviewCard
        textContent={<Markdown children={getContent(currentLang)} />}
        btnViewText={t("buttons.view")}
        card={null}
      />
    </BaseSolutionPage>
  );
};

function getContent(currentLang) {
  if (currentLang?.value === "en") return enContent;
  return viContent;
}

const enContent = `
TBU
`;

const viContent = `
*   Cung cấp hình ảnh đúng thời gian thực.
*   Lưu trữ lớn, có thể truy xuất dữ liệu.
*   Đa dạng tùy chọn cấu hình phù hợp.
*   Hình ảnh sắc nét, công nghệ hồng ngoại quan sát ngày đêm.
*   Cấu trúc hệ thống linh hoạt, không hạn chế khả năng mở rộng, tính thẩm mỹ cao.
*   Khả năng tích hợp công nghệ thứ 3: Nhận diện khách VIP, báo động trộm, gọi thang máy...
`;
