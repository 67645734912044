import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";

// components
import OneColLinkDetailsWithHeading from "components/cards/OneColLinkDetailsWithHeading";

// helpers
import dataConfig from "dataConfig";

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-start items-stretch md:flex-row flex-wrap max-w-screen-xl`}
`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3`}
`;

export default () => {
  const { t } = useTranslation();

  const businessCards = useMemo(() => {
    return dataConfig.erpSolutions(t).business.map((solution) => ({
      title: solution.title,
      href: solution.url,
      description: solution.shortDescription,
    }));
  }, [t]);

  const internalCards = useMemo(() => {
    return dataConfig.erpSolutions(t).internal.map((solution) => ({
      title: solution.title,
      href: solution.url,
      description: solution.shortDescription,
    }));
  }, [t]);

  const operationalCards = useMemo(() => {
    return dataConfig.erpSolutions(t).operational.map((solution) => ({
      title: solution.title,
      href: solution.url,
      description: solution.shortDescription,
    }));
  }, [t]);

  return (
    <ThreeColumnContainer>
      <Column>
        <OneColLinkDetailsWithHeading
          cards={businessCards}
          heading={t("home.solutions.erp.business")}
        />
      </Column>
      <Column>
        <OneColLinkDetailsWithHeading
          cards={internalCards}
          heading={t("home.solutions.erp.internal")}
        />
      </Column>
      <Column>
        <OneColLinkDetailsWithHeading
          cards={operationalCards}
          heading={t("home.solutions.erp.operational")}
        />
      </Column>
    </ThreeColumnContainer>
  );
};
