import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------

const LANGS = [
  {
    label: "English",
    value: "en",
    icon: "https://minimal-assets-api.vercel.app/assets/icons/ic_flag_en.svg",
  },
  {
    label: "Việt Nam",
    value: "vi",
    icon: "https://minimal-assets-api.vercel.app/assets/icons/ic_flag_vn.svg",
  },
];

export default function useLocales() {
  const { i18n, t } = useTranslation();
  const langStorage = localStorage.getItem("i18nextLng");
  const currentLang =
    LANGS.find((_lang) => _lang.value === langStorage) || LANGS[1];

  const handleChangeLanguage = (newlang) => {
    i18n.changeLanguage(newlang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    t,
    currentLang,
    allLang: LANGS,
  };
}
