// hooks
import useLocales from "hooks/useLocales";

// helpers
import TwoColWithTextAndPreviewCard from "components/features/TwoColWithTextAndPreviewCard";

// sections
import BaseSolutionPage from "./BaseSolutionPage";
import Markdown from "components/markdown/Markdown";

export default () => {
  const { t, currentLang } = useLocales();

  const headingText = t("jmtHr.title");

  return (
    <BaseSolutionPage headingText={headingText}>
      <TwoColWithTextAndPreviewCard
        textContent={<Markdown children={getContent(currentLang)} />}
        btnViewText={t("buttons.view")}
        card={null}
      />
    </BaseSolutionPage>
  );
};

function getContent(currentLang) {
  if (currentLang?.value === "en") return enContent;
  return viContent;
}

const enContent = `
TBU
`;

const viContent = `
### Hệ thống quản lý nhân viên

*   Quản lý & hệ thống hoá dữ liệu nhân sự, mô hình hóa cơ cấu tổ chức doanh nghiệp.
*   Quản lý, theo dõi biến động và phân bổ quỹ lương cho từng bộ phận.
*   Quản lý lịch sử làm việc của nhân viên.
*   Quản trị mục tiêu (KPI, OKR) của từng cá nhân, phòng ban. Đo lường hiệu suất làm việc chính xác theo dữ liệu.
*   Báo cáo.
    

### Ứng dụng nhân viên

*   Thông tin nhân viên.
*   Lịch sử làm việc.
*   Đăng ký nghỉ, đăng ký công tác, đăng ký OT.
*   Phê duyệt đăng ký.
*   Thông tin, thông báo từ doanh nghiệp.
`;
