import tw from "twin.macro";

// components
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
// import Footer from "components/footers/FiveColumnWithInputForm.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import Markdown from "components/markdown/Markdown";
import { SectionHeading } from "components/misc/Headings";

// helpers
import dataConfig from "dataConfig";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

// hooks
import useLocales from "hooks/useLocales";
import MegaMenu from "modules/megaMenu/MegaMenu";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;

export default () => {
  const { t, currentLang } = useLocales();

  return (
    <AnimationRevealPage>
      <MegaMenu title={dataConfig.site.logoTrail} />

      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{t("privacy.title")}</Heading>
          </HeadingRow>
          <Markdown children={getContent(currentLang)} />
        </ContentWithPaddingXl>
      </Container>

      <Footer
        title={dataConfig.site.logoTrail}
        copyright={dataConfig.site.copyright}
        socialLinks={null}
        navLinks={dataConfig.footer.navLinks(t)}
      />
    </AnimationRevealPage>
  );
};

export function getContent(currentLang) {
  if (currentLang?.value === "en") return enContent;
  return viContent;
}

const enContent = `
TBU
`;

const viContent = `
Chính sách quyền riêng tư này mô tả cách thức mà Công ty cổ phần công nghệ JMT, trụ sở đặt tại Tầng 16, Tòa nhà TNR, số 54A Nguyễn Chí Thanh, Đống Đa, Hà Nội, Việt Nam (“JMT Technology JSC”) thu thập và sử dụng thông tin cá nhân của quý khách liên quan đến các trang web, ứng dụng, sản phẩm và dịch vụ của JMT Technology JSC (gọi chung là “Sản Phẩm Của Chúng Tôi”).

### 1. Thông tin cá nhân chúng tôi thu thập

Chúng tôi thu thập thông tin cá nhân của quý khách trong quá trình cung cấp Sản Phẩm Của Chúng Tôi cho quý khách. Việc thu thập thông tin cá nhân của quý khách sẽ được thực hiện theo quy định của Chính sách Quyền Riêng Tư này và quy định pháp luật về bảo vệ dữ liệu cá nhân hiện hành của quốc gia tương ứng.

Dưới đây là các loại thông tin được chúng tôi thu thập:

*   Thông tin quý khách cung cấp cho chúng tôi: Chúng tôi thu thập bất kỳ thông tin nào quý khách cung cấp liên quan đến Sản Phẩm Của Chúng Tôi.
*   Thông tin tự động: Chúng tôi tự động thu thập một số loại thông tin nhất định khi quý khách tương tác với Sản Phẩm Của Chúng Tôi. Thông tin mà chúng tôi có thể thu thập tự động khi quý khách sử dụng dịch vụ của chúng tôi bao gồm những thông tin về (trong giới hạn pháp luật cho phép): Tên thiết bị, model máy, phiên bản phần mềm hiện tại, số IMEI, địa chỉ IP, wifi SSID, thông tin mạng di động, thông tin pin, thông tin tài khoản, thông tin ứng dụng đã cài đặt (ví dụ như tên gói cài đặt, phiên bản, binary, ...), báo cáo vấn đề bảo mật.
*   Thông tin từ các nguồn khác: Chúng tôi có thể thu thập thông tin về quý khách từ các nguồn khác, bao gồm nhà cung cấp dịch vụ, đối tác và các nguồn công khai có sẵn.

### 2. Cách thức chúng tôi sử dụng thông tin cá nhân

Chúng tôi sử dụng thông tin cá nhân của quý khách để vận hành, cung cấp và cải thiện Sản Phẩm Của Chúng Tôi. Mục đích sử dụng thông tin cá nhân của chúng tôi bao gồm:

*   Cung cấp Sản Phẩm Của Chúng Tôi: Chúng tôi sử dụng thông tin cá nhân của quý khách để cung cấp và giao Sản Phẩm Của Chúng Tôi và xử lý các giao dịch liên quan đến Sản Phẩm Của Chúng Tôi, bao gồm đăng ký, mua hàng và thanh toán.
*   Đo lường, hỗ trợ và cải thiện Sản Phẩm Của Chúng Tôi: Chúng tôi sử dụng thông tin cá nhân của quý khách để đo lường việc sử dụng, phân tích hiệu suất, sửa lỗi, cung cấp hỗ trợ, cải thiện và phát triển Sản Phẩm Của Chúng Tôi.
*   Đưa ra khuyến nghị và cá nhân hóa trải nghiệm: Chúng tôi sử dụng thông tin cá nhân của quý khách để đề xuất Sản Phẩm Của Chúng Tôi mà quý khách có thể quan tâm, nhận diện sở thích của quý khách và cá nhân hóa trải nghiệm của quý khách với Sản Phẩm Của Chúng Tôi.
*   Tuân thủ nghĩa vụ pháp lý: Trong một số trường hợp nhất định, chúng tôi có nghĩa vụ pháp lý để thu thập, sử dụng hoặc lưu trữ thông tin cá nhân của quý khách.
*   Liên lạc với quý khách: Chúng tôi sử dụng thông tin cá nhân của quý khách để liên lạc với quý khách liên quan đến Sản Phẩm Của Chúng Tôi thông qua các kênh khác nhau (ví dụ: email, chat) và để trả lời yêu cầu của quý khách.
*   Tiếp thị: Chúng tôi sử dụng thông tin cá nhân của quý khách để tiếp thị và quảng bá Sản Phẩm Của Chúng Tôi khi được sự đồng ý trước đó của quý khách và phù hợp với các quy định của pháp luật. Chúng tôi có thể hiển thị quảng cáo Sản Phẩm Của Chúng Tôi dựa trên sở thích của quý khách.
*   Một số mục đích cụ thể cần sự đồng ý của quý khách: Chúng tôi có thể xin chấp thuận của quý khách về việc sử dụng thông tin cá nhân cho một mục đích cụ thể nào đó khi chúng tôi liên hệ với quý khách và các mục đích hợp pháp khác được phép theo quy định của pháp luật.

### 3. Thu thập và sử dụng cookies

Chúng tôi sử dụng cookie, pixel và các công nghệ tương tự khác (gọi chung là “cookie”) để nhận diện trình duyệt hoặc thiết bị của quý khách, tìm hiểu thêm về sở thích của quý khách, cung cấp cho quý khách các tính năng và dịch vụ thiết yếu và cho các mục đích bổ sung khác, bao gồm:

*   Nhận diện quý khách khi quý khách sử dụng hoặc đăng nhập sử dụng dịch vụ của chúng tôi. Điều này cho phép chúng tôi cung cấp cho quý khách các đề xuất, hiển thị nội dung được cá nhân hóa và cung cấp các tính năng và dịch vụ tùy chỉnh khác.
*   Lưu tâm đến các tùy chọn mà quý khách đã chấp thuận. Điều này cho phép chúng tôi tôn trọng những điều quý khách thích và không thích, chẳng hạn như tùy chọn ngôn ngữ và cấu hình của quý khách.
*   Tiến hành nghiên cứu và phân tích để cải thiện dịch vụ của chúng tôi.
*   Ngăn chặn hành vi gian lận.
*   Cải thiện an ninh.
*   Cung cấp nội dung, bao gồm quảng cáo, có liên quan đến sở thích của quý khách trên các trang web của chúng tôi và trang web của bên thứ ba.
*   Đo lường và phân tích chất lượng của các Sản Phẩm Của Chúng Tôi.

Cookie cho phép quý khách tận dụng một số tính năng cần thiết của chúng tôi. Ví dụ, nếu quý khách chặn hoặc từ chối cookie của chúng tôi, quý khách sẽ không thể sử dụng một số sản phẩm, dịch vụ nhất định yêu cầu quý khách đăng nhập hoặc quý khách có thể phải tự tay điều chỉnh một số tùy chọn hoặc cài đặt ngôn ngữ mỗi khi quý khách truy cập lại các trang web của chúng tôi.

Các bên thứ ba được chấp thuận cũng có thể đặt cookie khi quý khách tương tác với các Sản Phẩm Của Chúng Tôi. Các bên thứ ba này thường bao gồm các công cụ tìm kiếm, nhà cung cấp dịch vụ đo lường và phân tích, mạng xã hội và các công ty quảng cáo. Các bên thứ ba sử dụng cookie trong quá trình cung cấp nội dung, bao gồm quảng cáo liên quan đến sở thích của quý khách, để đo lường hiệu quả của quảng cáo và thực hiện một số dịch vụ thay mặt cho chúng tôi.

Quý khách có thể quản lý cookie trình duyệt bằng việc cài đặt trình duyệt của mình. Tính năng 'Trợ giúp' trên hầu hết các trình duyệt sẽ cho quý khách biết cách ngăn trình duyệt chấp nhận các cookie mới, cách trình duyệt thông báo cho quý khách khi quý khách nhận được cookie mới, cách tắt cookie và khi cookie hết hạn. Nếu quý khách tắt tất cả cookie trên trình duyệt của mình, cả chúng tôi và bên thứ ba sẽ không thể chuyển cookie sang trình duyệt của quý khách. Tuy nhiên, nếu quý khách làm điều này, quý khách có thể phải tự tay điều chỉnh một số tùy chọn mỗi khi quý khách truy cập lại trang web và một số tính năng và dịch vụ có thể không hoạt động.

### 4. Cách thức chúng tôi chia sẻ thông tin cá nhân

Thông tin về khách hàng là một phần quan trọng trong hoạt động của chúng tôi, và chúng tôi không bán thông tin cá nhân của khách hàng cho người khác. Các bên thứ ba này đều tuân thủ Chính sách quyền riêng tư này.

*   Giao dịch liên quan đến bên thứ ba: Chúng tôi cung cấp cho quý khách các dịch vụ, phần mềm và nội dung do bên thứ ba cung cấp để sử dụng trên hoặc thông qua Sản Phẩm Của Chúng Tôi. Quý khách có thể biết khi nào bên thứ ba tham gia vào các giao dịch của quý khách và khi nào chúng tôi sẽ chia sẻ thông tin liên quan đến các giao dịch đó với bên thứ ba đó.
*   Bên thứ ba cung cấp dịch vụ: Chúng tôi thuê các công ty và cá nhân khác để thực hiện một số công việc thay mặt chúng tôi. Ví dụ bao gồm: gửi thông tin liên lạc, xử lý thanh toán, đánh giá rủi ro tín dụng và tuân thủ, phân tích dữ liệu, cung cấp hỗ trợ tiếp thị và bán hàng (bao gồm quản lý quảng cáo và sự kiện), quản lý quan hệ khách hàng và đào tạo. Các bên thứ ba cung cấp dịch vụ này có quyền truy cập vào thông tin cá nhân cần thiết để thực hiện các chức năng của họ, nhưng không được sử dụng cho các mục đích khác. Ngoài ra, họ phải tuân thủ Chính sách quyền riêng tư này, nghĩa vụ bảo vệ dữ liệu trong thỏa thuận với chúng tôi và pháp luật về bảo vệ quyền riêng tư liên quan.
*   Chuyển nhượng doanh nghiệp: Trong quá trình phát triển kinh doanh, chúng tôi có thể bán hoặc mua các doanh nghiệp hoặc dịch vụ khác. Trong các giao dịch như vậy, thông tin cá nhân nói chung là một trong những tài sản kinh doanh được chuyển nhượng nhưng vẫn phải tuân theo các quy định của Chính sách quyền riêng tư này (hoặc khi được khách hàng chấp thuận). Ngoài ra, trong trường hợp JMT Technology JSC hoặc phần lớn tài sản của JMT Technology JSC được một công ty khác mua, thông tin của quý khách sẽ đương nhiên trở thành một trong những tài sản được chuyển nhượng, trong phạm vi pháp luật cho phép.
*   Bảo vệ chúng tôi và những người khác: Chúng tôi tiết lộ tài khoản và thông tin cá nhân khác khi chúng tôi tin rằng việc đó là phù hợp để tuân thủ pháp luật, để thực thi hoặc áp dụng các điều khoản và thỏa thuận khác của chúng tôi hoặc để bảo vệ quyền, tài sản hoặc an ninh của chúng tôi, khách hàng của chúng tôi, hoặc bất kỳ người nào khác. Các công việc nêu trên có thể bao gồm việc trao đổi thông tin với các công ty và tổ chức khác để ngăn chặn và phát hiện gian lận và giảm rủi ro tín dụng.
*   Tùy theo sự lựa chọn của quý khách: Ngoài các quy định trên, quý khách sẽ nhận được thông báo khi thông tin cá nhân về quý khách có thể được chia sẻ với bên thứ ba và quý khách sẽ có quyền không chấp thuận việc chia sẻ thông tin.

### 5. Vị trí của thông tin cá nhân

JMT Technology JSC có địa chỉ tại Việt Nam. Tùy thuộc vào phạm vi tương tác của quý khách với Sản Phẩm Của Chúng Tôi, thông tin cá nhân của quý khách có thể được lưu trữ tại hoặc truy cập từ nhiều quốc gia, bao gồm cả Việt Nam. Khi chúng tôi chuyển thông tin cá nhân của quý khách đến các quốc gia khác, chúng tôi sẽ đảm bảo rằng thông tin đó được chuyển theo Chính sách quyền riêng tư này và được cho phép theo pháp luật bảo vệ quyền riêng tư hiện hành của quốc gia tương ứng.

### 6. Cách thức chúng tôi bảo vệ thông tin cá nhân

Tại JMT Technology JSC, bảo mật là ưu tiên cao nhất của chúng tôi. Hệ thống của chúng tôi được thiết kế có tính đến khả năng bảo đảm an toàn và riêng tư cho thông tin của quý khách.

*   Chúng tôi có các biện pháp thích hợp về kỹ thuật và an ninh để ngăn chặn việc truy cập, sử dụng trái phép thông tin cá nhân. Chúng tôi cũng thường xuyên phối hợp với các chuyên gia bảo mật nhằm cập nhật những thông tin mới nhất về an ninh mạng để đảm bảo sự an toàn cho thông tin cá nhân. Khi thu thập dữ liệu, chúng tôi thực hiện lưu giữ và bảo mật thông tin cá nhân tại hệ thống máy chủ và các thông tin cá nhân này được bảo đảm an toàn bằng các hệ thống tường lửa, các biện pháp kiểm soát truy cập, mã hóa dữ liệu.
*   Các thông tin thẻ thanh toán của quý khách do các tổ chức tài chính phát hành được chúng tôi bảo vệ theo tiêu chuẩn quốc tế với nguyên tắc không ghi nhận các dữ liệu quan trọng của thẻ thanh toán (số thẻ, họ tên, số CVV) trên hệ thống của chúng tôi. Giao dịch thanh toán của quý khách được thực hiện trên hệ thống của ngân hàng liên quan.

### 7. Quảng cáo trên Internet và bên thứ ba

Các Sản Phẩm Của Chúng Tôi có thể bao gồm quảng cáo của bên thứ ba và đường dẫn tới các trang web và ứng dụng khác. Các đối tác quảng cáo bên thứ ba có thể thu thập thông tin về quý khách khi quý khách tương tác với nội dung, quảng cáo hoặc dịch vụ của họ.

### 8. Truy cập và lựa chọn

Quý khách có thể xem, cập nhật và xóa một số thông tin nhất định về tài khoản và các tương tác của quý khách với Sản Phẩm Của Chúng Tôi. Nếu quý khách không thể tự truy cập hoặc cập nhật thông tin của mình, quý khách luôn có thể liên hệ với chúng tôi để được hỗ trợ.

Quý khách có nhiều lựa chọn về việc thu thập và sử dụng thông tin cá nhân của quý khách. Nhiều Sản Phẩm Của Chúng Tôi bao gồm chức năng cho phép quý khách tùy chọn về cách thông tin của quý khách đang được sử dụng. Quý khách có thể chọn không cung cấp một số thông tin nhất định, nhưng sau đó quý khách có thể không tận dụng được một số Sản Phẩm Của Chúng Tôi.

### 9. Thông tin cá nhân của người chưa thành niên

Nếu quý khách dưới 18 tuổi, quý khách chỉ có thể ký kết hợp đồng và chấp thuận Chính sách quyền riêng tư này với sự đồng ý của cha mẹ hoặc người giám hộ.

### 10. Thời hạn lưu trữ thông tin cá nhân

Chúng tôi lưu trữ thông tin cá nhân của quý khách để đảm bảo cho quý khách khả năng sử dụng liên tục các Sản Phẩm Của Chúng Tôi, và lưu trữ trong thời hạn cần thiết để thực hiện được các mục tiêu quy định tại Chính sách quyền riêng tư này, hoặc theo quy định của pháp luật (bao gồm cả cho mục đích thuế và kế toán), hoặc để thực hiện các công việc khác như được thông báo trước cho quý khách. Thời gian chúng tôi lưu giữ thông tin cá nhân cụ thể khác nhau tùy thuộc vào mục đích sử dụng và chúng tôi sẽ xóa thông tin cá nhân của quý khách theo quy định của pháp luật hiện hành.

### 11. Thông tin liên lạc, thông báo và sửa đổi

Nếu quý khách có bất kỳ câu hỏi nào về quyền riêng tư tại JMT Technology JMT hoặc muốn liên hệ với đơn vị kiểm soát thông tin của chúng tôi, vui lòng liên hệ với chúng tôi qua trang Liên Hệ và chúng tôi sẽ cố gắng trả lời câu hỏi của quý khách.

Hoạt động kinh doanh của chúng tôi liên tục thay đổi và Chính sách quyền riêng tư này cũng có thể được sửa đổi. Quý khách nên truy cập và kiểm tra trang web của chúng tôi thường xuyên để cập nhật những thay đổi gần nhất. Trừ trường hợp có quy định khác, Chính sách quyền riêng tư hiện tại của chúng tôi áp dụng cho tất cả thông tin cá nhân chúng tôi có về quý khách và tài khoản của quý khách. Chúng tôi sẽ không bao giờ sửa đổi đáng kể Chính sách quyền riêng tư này mà có thể làm giảm mức độ bảo vệ thông tin cá nhân được thu thập trong quá khứ mà không thông báo cho khách hàng liên quan và cho họ quyền để lựa chọn.

### 12. Một số thông tin bổ sung cho Liên minh châu Âu

Chúng tôi cung cấp thêm một số thông tin về quyền riêng tư, việc thu thập và sử dụng thông tin cá nhân của khách hàng tiềm năng và khách hàng hiện tại của chúng tôi tại các quốc gia thuộc Liên Minh Châu Âu.

*   Đơn Vị Kiểm Soát Thông Tin Cá Nhân. Công ty cổ phần công nghệ JMT (địa chỉ tại Tầng 16, Tòa nhà TNR, số 54A Nguyễn Chí Thanh, Đống Đa, Hà Nội, Việt Nam) là đơn vị kiểm soát các thông tin cá nhân được thu thập hoặc xử lý theo Chính sách quyền riêng tư này.
*   Xử lý. Chúng tôi xử lý thông tin cá nhân của quý khách dựa trên một hoặc một số cơ sở pháp lý sau đây:
    *   Cần thiết để ký kết hợp đồng với quý khách hoặc với tổ chức mà quý khách đại diện, để thực hiện các nghĩa vụ hợp đồng, để cung cấp các Sản Phẩm Của Chúng Tôi, để phản hồi các yêu cầu từ quý khách, hoặc để hỗ trợ khách hàng;
    *   Cần thiết để ký kết hợp đồng với quý khách hoặc với tổ chức mà quý khách đại diện, để thực hiện các nghĩa vụ hợp đồng, để cung cấp các Sản Phẩm Của Chúng Tôi, để phản hồi các yêu cầu từ quý khách, hoặc để hỗ trợ khách hàng;
    *   Cần thiết để tuân thủ pháp luật và các nghĩa vụ pháp lý;
    *   Để trả lời các yêu cầu hợp pháp; hoặc
    *   Dựa trên sự chấp thuận của quý khách.
*   Quyền của quý khách. Theo quy định của pháp luật, quý khách có các quyền sau:
    *   Hỏi chúng tôi có đang nắm giữ thông tin cá nhân về bản thân hay không và yêu cầu cung cấp bản sao thông tin cá nhân đó và thông tin về cách thức các thông tin đó được xử lý;
    *   Yêu cầu đính chính thông tin cá nhân không chính xác;
    *   Yêu cầu xóa thông tin cá nhân không còn cần thiết cho các mục đích xử lý, các thông tin được xử lý dựa trên sự chấp thuận nhưng chấp thuận đó đã được rút lại, hoặc thông tin được xử lý không tuân thủ các quy định của pháp luật hiện hành;
    *   Yêu cầu chúng tôi tạm dừng xử lý thông tin cá nhân khi hoạt động xử lý không phù hợp;
    *   Phản đối việc xử lý dữ liệu cá nhân;
    *   Yêu cầu luân chuyển thông tin cá nhân mà quý khách đã cung cấp cho chúng tôi (không bao gồm thông tin có được từ các thông tin được thu thập), trong trường hợp việc xử lý thông tin cá nhân đó được thực hiện trên cơ sở sự đồng ý hoặc hợp đồng với quý khách và được thực hiện bằng phương thức tự động; và
    *   Gửi khiếu nại với cơ quan có thẩm quyền.

Quý khách có thể thực hiện các quyền truy cập, đính chính, xóa, yêu cầu tạm dừng, phản đối và yêu cầu luân chuyển thông tin bằng cách liên hệ với chúng tôi. Nếu quý khách muốn thực hiện bất kỳ quyền gì nêu trên và quý khách là khách hàng của chúng tôi, vui lòng liên hệ với chúng tôi. Nếu quý khách không phải là khách hàng của chúng tôi, vui lòng liên hệ với chúng tôi theo địa chỉ tại Mục 11 ở trên.

Khi quý khách đồng ý cho chúng tôi xử lý thông tin cá nhân của mình cho một mục đích cụ thể, quý khách có thể rút lại sự đồng ý đó bất cứ lúc nào và chúng tôi sẽ ngừng mọi hoạt động xử lý dữ liệu của quý khách cho mục đích đó.

*   Chuyển thông tin ra ngoài Liên Minh Châu Âu. Khi chúng tôi chuyển thông tin cá nhân của quý khách ra ngoài Liên Minh Châu Âu, chúng tôi sẽ thực hiện theo các điều khoản của Chính sách quyền riêng tư này và pháp luật bảo vệ quyền riêng tư hiện hành. Điều này có thể bao gồm việc chuyển dữ liệu theo các thỏa thuận chuyển dữ liệu có bao gồm Điều khoản hợp đồng tiêu chuẩn được Ủy ban Châu Âu phê duyệt.

### 13. Ví dụ về các thông tin được chúng tôi thu thập

**Thông tin quý khách cung cấp cho chúng tôi.**

Quý khách chủ yếu cung cấp thông tin cho chúng tôi khi quý khách:

*   Tìm kiếm, đăng ký hoặc mua Sản Phẩm Của Chúng Tôi;
*   Tạo hoặc quản lý tài khoản của quý khách;
*   Cấu hình cài đặt của quý khách cho, cung cấp quyền truy cập dữ liệu cho, hoặc tương tác với Sản Phẩm Của Chúng Tôi;
*   Đăng ký hoặc tham dự một sự kiện của JMT Technology JSC;
*   Mua hoặc sử dụng nội dung, sản phẩm hoặc dịch vụ từ các nhà cung cấp bên thứ ba thông qua trang web hoặc ứng dụng của JMT Technology JSC (hoặc các địa điểm tương tự khác do chúng tôi điều hành hoặc cung cấp);
*   Cung cấp nội dung, sản phẩm hoặc dịch vụ của quý khách trên hoặc thông qua Sản Phẩm Của Chúng Tôi hoặc trang web hoặc ứng dụng của JMT Technology JSC (hoặc các địa điểm tương tự khác do chúng tôi điều hành hoặc cung cấp);
*   Liên lạc với chúng tôi;
*   Hoàn thành bảng câu hỏi hoặc các phiếu cung cấp thông tin khác;
*   Đăng tải nội dung trên các trang web hoặc ứng dụng của JMT Technology JSC hoặc các địa điểm tương tự.

Tùy thuộc vào việc quý khách sử dụng Sản Phẩm Của Chúng Tôi, quý khách có thể cung cấp cho chúng tôi các thông tin ví dụ như:

*   Tên, địa chỉ email, địa chỉ thực, số điện thoại và thông tin liên lạc tương tự khác;
*   Thông tin thanh toán, bao gồm thông tin thẻ tín dụng và tài khoản ngân hàng;
*   Thông tin về vị trí của quý khách;
*   Thông tin về tổ chức của quý khách và đầu mối liên hệ của quý khách, chẳng hạn như đồng nghiệp hoặc những người trong tổ chức của quý khách;
*   Tên người dùng, bí danh, vai trò và thông tin xác thực và bảo mật khác;
*   Nội dung phản hồi, lời chứng thực, câu hỏi, trao đổi với chúng tôi;
*   Hình ảnh của quý khách (tĩnh, video và trong một số trường hợp 3-D), giọng nói và các đặc điểm nhận dạng cá nhân khác của quý khách khi quý khách tham dự một sự kiện của JMT Technology JSC hoặc sử dụng một số Sản Phẩm Của Chúng Tôi trong phạm vi được phép và theo đúng trình tự thủ tục pháp luật hiện hành quy định;
*   Hình ảnh của quý khách (tĩnh, video và trong một số trường hợp 3-D), giọng nói và các đặc điểm nhận dạng cá nhân khác của quý khách khi quý khách tham dự một sự kiện của JMT Technology JSC hoặc sử dụng một số Sản Phẩm Của Chúng Tôi trong phạm vi được phép và theo đúng trình tự thủ tục pháp luật hiện hành quy định;
*   Thông tin tài chính và doanh nghiệp; và
*   Số VAT và các định danh thuế khác.

**Thông tin tự động**

Chúng tôi chủ yếu thu thập thông tin tự động khi quý khách:

*   Truy cập, tương tác hoặc sử dụng Sản Phẩm Của Chúng Tôi (kể cả khi quý khách sử dụng máy tính hoặc thiết bị khác để tương tác với Sản Phẩm Của Chúng Tôi);
*   Tải nội dung từ chúng tôi;
*   Mở email hoặc nhấp vào liên kết trong email từ chúng tôi; và
*   Tương tác hoặc liên lạc với chúng tôi.
*   Thông tin mạng và kết nối, như địa chỉ giao thức Internet (IP) được sử dụng để kết nối máy tính (hoặc thiết bị khác) với Internet và thông tin về nhà cung cấp dịch vụ Internet của quý khách;
*   Thông tin về máy tính và thiết bị, chẳng hạn như thiết bị, ứng dụng, hoặc loại và phiên bản trình duyệt, loại và phiên bản của plug-in của trình duyệt, hệ điều hành hoặc cài đặt múi giờ;
*   Vị trí của thiết bị hoặc máy tính của quý khách;
*   Thông tin xác thực và bảo mật;
*   Thông tin tương tác nội dung, chẳng hạn như nội dung được tải xuống, luồng và chi tiết phát lại, bao gồm thời lượng và số lượng luồng và tải xuống đồng thời;
*   Các số liệu Sản Phẩm Của Chúng Tôi, chẳng hạn như việc sử dụng sản phẩm, lỗi kỹ thuật, báo cáo, tùy chọn cài đặt của quý khách, thông tin sao lưu backup, API calls, và nhật ký khác;
*   Nội dung quý khách đã xem hoặc tìm kiếm, thời gian phản hồi của trang web, và thông tin tương tác trang (như cuộn, nhấp chuột);
*   Địa chỉ email và số điện thoại được sử dụng để liên hệ với chúng tôi; và
*   Thông tin nhận dạng và thông tin có trong cookie.

**Thông tin từ các nguồn khác**

Ví dụ về các thông tin được chúng tôi thu thập tự động:

*   Thông tin tiếp thị, tạo doanh số và tuyển dụng, bao gồm tên, địa chỉ email, địa chỉ thực, số điện thoại và thông tin liên hệ tương tự khác;
*   Thông tin về đăng ký, mua, hỗ trợ hoặc thông tin khác về tương tác của quý khách với các sản phẩm, dịch vụ của chúng tôi hoặc với các sản phẩm của bên thứ ba liên quan đến Sản Phẩm Của Chúng Tôi;
*   Kết quả tìm kiếm và liên kết, bao gồm các danh sách sản phẩm, dịch vụ được trả tiền (như Liên kết được Tài trợ); và
*   Thông tin lịch sử tín dụng.
`;
