import styled from "styled-components";
import tw from "twin.macro";

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-start items-stretch md:flex-row flex-wrap max-w-screen-lg mx-auto py-3`}
`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-start sm:text-left h-full mx-3 py-3`}
  .textContainer {
    ${tw`sm:ml-4 mt-2`}
  }
  .title {
    ${tw`mt-4 tracking-wide font-bold text-base leading-none`}
  }
  .description {
    ${tw`mt-1 font-medium text-secondary-100 leading-loose text-sm`}
  }
`;

export default ({ cards = null }) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) title - the title of the card
   *  2) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      title: "Secure",
      href: "#",
      description:
        "We strictly only deal with vendors that provide top notch security.",
    },
    {
      title: "24/7 Support",
      href: "#",
      description:
        "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud.",
    },
    {
      title: "Reliable",
      href: "#",
      description:
        "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud.",
    },
    {
      title: "Easy",
      href: "#",
      description:
        "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud.",
    },
    {
      title: "Customizable",
      href: "#",
      description:
        "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud.",
    },
    {
      title: "Fast",
      href: "#",
      description:
        "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud.",
    },
  ];

  if (!cards) cards = defaultCards;

  return (
    <ThreeColumnContainer>
      {cards.map((card, i) => (
        <Column key={i}>
          <Card>
            <span className="textContainer">
              <a className="title" href={card.href}>
                {card.title || "Fully Secure"}
              </a>
              <p className="description">
                {card.description ||
                  "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
              </p>
            </span>
          </Card>
        </Column>
      ))}
    </ThreeColumnContainer>
  );
};
