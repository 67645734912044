import Header, {
  CollapsableNavLink,
  CollapsablePrimaryLink,
  collapseBreakPointCssMap,
  DesktopNavLinks,
  LogoLink,
  NavLink,
  NavLinks,
  OverlayContainer,
  SectionNavLink,
} from "components/headers/megaLight";
import useLocales from "hooks/useLocales";
import { useMemo, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import ErpCollections from "./ErpCollections";
import IotCollections from "./IotCollections";

const HeaderForDarkBg = styled(Header)`
  ${tw`max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${DesktopNavLinks} {
    .active {
      ${tw`border-gray-300`}
    }
  }
`;

export default ({
  title = "Treact",
  isBgDark = false,
  collapseBreakpointClass = "lg",
}) => {
  const { t } = useLocales();

  const [activeSection, setActiveSection] = useState("");

  const handleChangeActiveSection = (section) => {
    setActiveSection(activeSection === section ? "" : section);
  };

  const collapseBreakpointCss =
    collapseBreakPointCssMap[collapseBreakpointClass];

  const links = (
    <NavLinks key={1}>
      <SectionNavLink
        as="span"
        className={activeSection === "erp" ? "active" : ""}
        onClick={() => handleChangeActiveSection("erp")}
      >
        {t("nav.erp")}
      </SectionNavLink>
      <SectionNavLink
        as="span"
        className={activeSection === "iot" ? "active" : ""}
        onClick={() => handleChangeActiveSection("iot")}
      >
        {t("nav.iot")}
      </SectionNavLink>
      <CollapsableNavLink
        href="/about-us"
        css={collapseBreakpointCss.DesktopNavLinks}
      >
        {t("nav.about")}
      </CollapsableNavLink>
      <CollapsablePrimaryLink
        href="/contact-us"
        css={collapseBreakpointCss.DesktopNavLinks}
      >
        {t("nav.contact")}
      </CollapsablePrimaryLink>
    </NavLinks>
  );

  const overlay = useMemo(() => {
    switch (activeSection) {
      case "iot":
        return (
          <OverlayContainer>
            <IotCollections />
          </OverlayContainer>
        );

      case "erp":
        return (
          <OverlayContainer>
            <ErpCollections />
          </OverlayContainer>
        );

      default:
        return null;
    }
  }, [activeSection]);

  return isBgDark ? (
    <HeaderForDarkBg links={links} title={title} overlay={overlay} hasLogoBg />
  ) : (
    <Header links={links} title={title} overlay={overlay} hasLogoBg />
  );
};
