import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components"; //eslint-disable-line
import tw from "twin.macro";

// components
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";

// hooks
import useLocales from "hooks/useLocales";

// helpers
import dataConfig from "dataConfig";

// modules
import MegaMenu from "modules/megaMenu/MegaMenu";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;

const ContentRow = tw.div`flex flex-col lg:flex-row`;
const ContentRowMain = tw.div`flex-1`;
const ContentRowSidebar = tw.aside`w-72 mt-12 lg:mt-0`;

const SidebarWrapper = tw.div`flex flex-col`;
const SidebarItem = styled.a`
  ${tw`text-sm text-gray-800 lg:mx-6 my-0 tracking-wide transition duration-300 py-1 hocus:text-primary-500`}
  &.isActive {
    ${tw`text-primary-500 cursor-default`}
  }
`;
const SidebarTitle = tw.h3`lg:mx-6 font-medium text-xl mb-3`;

const solutions = {
  iotSolutions: dataConfig.iotSolutions,
  erpSolutions: dataConfig.erpSolutions,
};

export default ({ headingText, children, category }) => {
  const { t } = useLocales();

  const location = useLocation();

  const relatedSolutions = useMemo(() => {
    if (typeof category !== "string") {
      return [];
    }

    const categories = category.split(".");

    if (categories.length !== 1 && categories.length !== 2) {
      return [];
    }

    if (categories.length === 1) {
      return solutions[category](t);
    }

    return Object.values(solutions[categories[0]](t)).flat();
  }, [category, t]);

  const sidebarLinks = (solutionList) => {
    return (
      <SidebarWrapper>
        {solutionList.map((solution) => {
          const isActive = location.pathname === solution.url;
          return (
            <SidebarItem
              href={solution.url}
              key={solution.title}
              as={isActive ? "div" : "a"}
              className={isActive ? "isActive" : ""}
            >
              {solution.title}
            </SidebarItem>
          );
        })}
      </SidebarWrapper>
    );
  };

  return (
    <AnimationRevealPage>
      <MegaMenu title={dataConfig.site.logoTrail} />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <ContentRow>
            <ContentRowMain>{children}</ContentRowMain>
            <ContentRowSidebar>
              <SidebarTitle>{t("solutionSidebar.title")}</SidebarTitle>
              {sidebarLinks(relatedSolutions)}
            </ContentRowSidebar>
          </ContentRow>
        </ContentWithPaddingXl>
      </Container>
      <Footer
        title={dataConfig.site.logoTrail}
        copyright={dataConfig.site.copyright}
        socialLinks={null}
        navLinks={dataConfig.footer.navLinks(t)}
      />
    </AnimationRevealPage>
  );
};
