// hooks
import useLocales from "hooks/useLocales";

// helpers
import TwoColWithTextAndPreviewCard from "components/features/TwoColWithTextAndPreviewCard";

// sections
import BaseSolutionPage from "./BaseSolutionPage";
import Markdown from "components/markdown/Markdown";

export default () => {
  const { t, currentLang } = useLocales();

  const headingText = t("jmtVehicleTracking.title");

  return (
    <BaseSolutionPage
      headingText={headingText}
      category="erpSolutions.operational"
    >
      <TwoColWithTextAndPreviewCard
        textContent={<Markdown children={getContent(currentLang)} />}
        btnViewText={t("buttons.view")}
        card={null}
      />
    </BaseSolutionPage>
  );
};

function getContent(currentLang) {
  if (currentLang?.value === "en") return enContent;
  return viContent;
}

const enContent = `
TBU
`;

const viContent = `
### Giám sát trực tuyến

*   Giám sát vị trí, tốc độ, trạng thái hoạt động của phương tiện theo thời gian thực.
*   Giám sát toàn bộ các trạng thái của xe được kết nối vào đầu cảm biến của thiết bị như: đóng mở cửa, tắt mở máy, phanh xe, cảm biến xăng dầu...

### Quản lý bảo dưỡng

*   Ghi nhận lịch sử bảo dưỡng xe: thay lốp, thay dầu, thay ắc quy...
*   Nhắc nhở lịch bảo dưỡng.
    
### Cảnh báo

*   Cảnh báo khi lái xe gửi tín hiệu cấp cứu.
*   Cảnh báo quá tốc độ, cảnh báo dừng lâu.  

### Báo cáo/thống kê

*   Báo cáo quá tốc độ, dừng đỗ xe trên tuyến.
*   Thống kê số lượt qua điểm kiểm soát đã định sẵn, số lượt tiếp nhiên liệu.
`;
