import useLocales from "hooks/useLocales";
// import tw from "twin.macro";

// components
// import ContactDetails from "components/cards/ThreeColContactDetails.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullFormspree";

// helpers
import { PrimaryText } from "components/misc/Typography";
import dataConfig from "dataConfig";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import MegaMenu from "modules/megaMenu/MegaMenu";

// const Address = tw.span`leading-relaxed`;
// const AddressLine = tw.span`block`;
// const Email = tw.span`text-sm mt-6 block text-gray-500`;
// const Phone = tw.span`text-sm mt-0 block text-gray-500`;

export default () => {
  const { t } = useLocales();

  return (
    <AnimationRevealPage>
      <MegaMenu title={dataConfig.site.logoTrail} />

      <ContactUsForm
        heading={
          <>
            {t("contact.heading1")}{" "}
            <PrimaryText> {t("contact.heading2")}</PrimaryText>
            <wbr /> {t("contact.heading3")}.
          </>
        }
        submitButtonText={t("contact.submitText")}
        subheading={t("contact.subheading")}
        description={t("contact.description")}
        formspreeCode={dataConfig.forms.formspree}
        textThanks={t("contact.forms.thanks")}
        textFields={{
          name: t("contact.forms.name"),
          message: t("contact.forms.message"),
          email: t("contact.forms.email"),
        }}
      />

      {/* <ContactDetails
        cards={[
          {
            title: "New York",
            description: (
              <>
                <Address>
                  <AddressLine>40 Gates Court</AddressLine>
                  <AddressLine>Endicott, NY 13760</AddressLine>
                </Address>
                <Email>contact@treact.com</Email>
                <Phone>+1 (203) 991-6988</Phone>
              </>
            ),
          },
          {
            title: "Illinois",
            description: (
              <>
                <Address>
                  <AddressLine>602 Annadale Drive</AddressLine>
                  <AddressLine>Dekalb, IL 60115</AddressLine>
                </Address>
                <Email>contact@treact.com</Email>
                <Phone>+1 (203) 991-6988</Phone>
              </>
            ),
          },
          {
            title: "California",
            description: (
              <>
                <Address>
                  <AddressLine>96 NE. Delaware Lane</AddressLine>
                  <AddressLine>Sacramento, CA 95820</AddressLine>
                </Address>
                <Email>contact@treact.com</Email>
                <Phone>+1 (203) 991-6988</Phone>
              </>
            ),
          },
          {
            title: "Tennessee",
            description: (
              <>
                <Address>
                  <AddressLine>74 Peachtree Ave.</AddressLine>
                  <AddressLine>Dyersburg, TN 38024</AddressLine>
                </Address>
                <Email>contact@treact.com</Email>
                <Phone>+1 (203) 991-6988</Phone>
              </>
            ),
          },
          {
            title: "New Jersey",
            description: (
              <>
                <Address>
                  <AddressLine>8355 Summer Street</AddressLine>
                  <AddressLine>Manchester, NJ 08759</AddressLine>
                </Address>
                <Email>contact@treact.com</Email>
                <Phone>+1 (203) 991-6988</Phone>
              </>
            ),
          },
          {
            title: "Ohio",
            description: (
              <>
                <Address>
                  <AddressLine>7713 Snake Hill Ave.</AddressLine>
                  <AddressLine>Piqua, OH 45356</AddressLine>
                </Address>
                <Email>contact@treact.com</Email>
                <Phone>+1 (203) 991-6988</Phone>
              </>
            ),
          },
        ]}
      /> */}

      <Footer
        title={dataConfig.site.logoTrail}
        copyright={dataConfig.site.copyright}
        socialLinks={null}
        navLinks={dataConfig.footer.navLinks(t)}
      />
    </AnimationRevealPage>
  );
};
