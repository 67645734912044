import styled from "styled-components";
import tw from "twin.macro";

// helpers
import PreviewCard from "components/cards/PreviewCard";

const Text = styled.div`
  ${tw`text-lg text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10 mb-3`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8 mb-3`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6 mb-3`}
  }
  ul {
    ${tw`list-disc`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

const TwoColumn = tw.div`p-4 flex justify-between items-start flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;

export default ({
  textContent = "text",
  card = {
    url: "#",
    imageSrc: "#",
  },
  btnViewText = "View",
}) => {
  const textValue =
    typeof textContent === "string" ? <Text>{textContent}</Text> : textContent;

  return (
    <TwoColumn>
      <LeftColumn>{textValue}</LeftColumn>
      {card && (
        <RightColumn>
          <PreviewCard btnViewText={btnViewText} card={card} />
        </RightColumn>
      )}
    </TwoColumn>
  );
};
