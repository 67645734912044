import useLocales from "hooks/useLocales";
import tw from "twin.macro";

// components
import MainFeature1 from "components/features/TwoColWithButton.js";
import Footer from "components/footers/MiniCenteredFooter.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
// import Values from "components/features/ThreeColSimple.js";
import Values from "components/features/ThreeColWithSideImage.js";
import Visions from "components/features/TwoColWithTwoFeaturesAndButtonsSimple";
// import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

// assets
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";
import GoodTeamIllustration from "images/illustrations/undraw_good_team.svg";
import WorkSpaceIllustration from "images/illustrations/undraw_shared_workspace.svg";

// helpers
import dataConfig from "dataConfig";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import MegaMenu from "modules/megaMenu/MegaMenu";

const Subheading = tw.span`uppercase tracking-wider text-sm`;

const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500`;

export default () => {
  const { t } = useLocales();

  return (
    <AnimationRevealPage>
      <MegaMenu title={dataConfig.site.logoTrail} />

      <MainFeature1
        subheading={
          <Subheading>
            {t("about.about")} {dataConfig.site.title}
          </Subheading>
        }
        heading={t("about.introductions.heading")}
        description={t("about.introductions.description")}
        buttonRounded={false}
        primaryButtonText={t("nav.contact")}
        primaryButtonUrl={"/contact-us"}
        imageSrc={WorkSpaceIllustration}
        // imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      />

      <Visions
        subheading={<Subheading>{t("about.visions.visions")}</Subheading>}
        heading={t("about.visions.heading")}
        description={t("about.visions.description")}
        buttonRounded={false}
        primaryButtonText={t("nav.contact")}
        primaryButtonUrl={""}
        imageSrc={GoodTeamIllustration}
        // imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
        features={[
          {
            Icon: FeatureIcon,
            title: t("about.visions.data.t1"),
            description: "",
          },
          {
            Icon: FeatureIcon,
            title: t("about.visions.data.t2"),
            description: "",
          },
          {
            Icon: FeatureIcon,
            title: t("about.visions.data.t3"),
            description: "",
          },
          {
            Icon: FeatureIcon,
            title: t("about.visions.data.t4"),
            description: "",
          },
        ]}
      />

      <Values
        subheading={<Subheading>{t("about.values.subheading")}</Subheading>}
        heading={t("about.values.heading")}
        description={t("about.values.description")}
        cards={dataConfig.values(t)}
        linkText=""
      />

      {/* <TeamCardGrid subheading={<Subheading>Our Team</Subheading>} /> */}

      <Footer
        title={dataConfig.site.logoTrail}
        copyright={dataConfig.site.copyright}
        socialLinks={null}
        navLinks={dataConfig.footer.navLinks(t)}
      />
    </AnimationRevealPage>
  );
};
