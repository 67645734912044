// hooks
import useLocales from "hooks/useLocales";

// helpers
import TwoColWithTextAndPreviewCard from "components/features/TwoColWithTextAndPreviewCard";

// sections
import BaseSolutionPage from "./BaseSolutionPage";

// components
import Markdown from "components/markdown/Markdown";

export default () => {
  const { t, currentLang } = useLocales();

  const headingText = t("jmtCustomerIdentify.title");

  return (
    <BaseSolutionPage
      headingText={headingText}
      category="erpSolutions.business"
    >
      <TwoColWithTextAndPreviewCard
        textContent={<Markdown children={getContent(currentLang)} />}
        btnViewText={t("buttons.view")}
        card={null}
      />
    </BaseSolutionPage>
  );
};

function getContent(currentLang) {
  if (currentLang?.value === "en") return enContent;
  return viContent;
}

const enContent = `
TBU
`;

const viContent = `
TBU
`;
