import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { motion } from "framer-motion";
import styled from "styled-components";
import { css } from "styled-components/macro";
import tw from "twin.macro";

const PreviewCardContainer = tw.div`mx-auto md:mx-0 max-w-lg w-full`;
const PreviewCard = tw(motion.a)`block rounded-lg shadow-raised`;
const PreviewCardImageContainer = tw.div`rounded-t-lg border-0 border-b-0`;
const PreviewCardImage = styled(motion.div)`
  ${(props) =>
    css`
      background-image: url("${props.$imageSrc}");
    `}
  ${tw`h-128 md:h-144 bg-cover bg-left-top`}
`;
const PreviewButton = tw(
  PrimaryButtonBase
)`w-full rounded-b-lg rounded-t-none py-5 font-semibold`;

export default ({
  card = {
    url: "#",
    imageSrc: "#",
  },
  btnViewText = "View",
}) => {
  const previewImageAnimationVariants = {
    rest: {
      backgroundPositionY: "0%",
    },
    hover: {
      backgroundPositionY: "100%",
      transition: {
        type: "tween",
        ease: "linear",
        duration: 5,
      },
    },
  };

  return (
    <PreviewCardContainer>
      <PreviewCard
        initial="rest"
        animate="rest"
        whileHover="hover"
        href={card.url}
      >
        <PreviewCardImageContainer>
          <PreviewCardImage
            transition={{ type: "tween" }}
            variants={previewImageAnimationVariants}
            $imageSrc={card.imageSrc}
          />
        </PreviewCardImageContainer>
        <PreviewButton>{btnViewText}</PreviewButton>
      </PreviewCard>
    </PreviewCardContainer>
  );
};
