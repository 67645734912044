import { useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import tw from "twin.macro";

// components
import { NavLink, NavLinks, PrimaryLink } from "components/headers/light";
import { PrimaryText } from "components/misc/Typography";
// import Services from "components/features/DashedBorderSixFeatures";
// import Services from "components/features/FourColWithSideImageWithPrimaryBackground.js";
// import Solutions from "components/features/ThreeColWithPreviewImage";
// import Solutions from "components/features/ThreeColSimple";
import Values from "components/features/ThreeColWithSideImageWithPrimaryBackground.js";
import Testimonials from "components/testimonials/TwoColumnWithImage";
import Footer from "components/footers/MiniCenteredFooter.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
import Hero, { SlantedBackground } from "components/hero/BackgroundAsImage.js";

// assets
import imgIllustration from "images/illustrations/undraw_solution_mindset.svg";

// helpers
import dataConfig from "dataConfig";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import scrollToElement from "helpers/scrollToElement";

// hooks
import useLocales from "hooks/useLocales";

// modules
import MegaMenu from "modules/megaMenu/MegaMenu";

const Actions = tw.div`flex flex-col sm:flex-row justify-center lg:justify-start`;
const ActionButton = tw(
  AnchorLink
)`px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 
  focus:shadow-outline focus:outline-none transition duration-300 mt-12 inline-block tracking-wide 
  text-center px-10 py-4 font-semibold tracking-normal`;
const PrimaryButton = tw(ActionButton)``;
const SecondaryButton = tw(
  ActionButton
)`mt-6 sm:mt-12 sm:ml-8 bg-gray-300 text-gray-800 hocus:bg-gray-400 hocus:text-gray-900`;

const ImageContainer = tw.div`p-12`;

const Text1 = tw.span`mb-2`;

export default () => {
  const { t } = useLocales();

  const navLinks = (
    <div>
      <NavLinks key={1}>
        <NavLink href="/about-us">{t("nav.about")}</NavLink>
      </NavLinks>
      <NavLinks key={2}>
        <PrimaryLink href="/contact-us">{t("nav.contact")}</PrimaryLink>
      </NavLinks>
    </div>
  );

  useEffect(() => {
    if (window.location.hash) {
      scrollToElement(window.location.hash);
    }
  }, []);

  const testimonials = dataConfig.testimonials(t);

  const values = dataConfig.values(t);

  return (
    <AnimationRevealPage>
      <Hero
        title={dataConfig.site.logoTrail}
        links={navLinks}
        notification={t("home.hero.notification")}
        heading={
          <>
            <Text1>{t("home.hero.heroText1")}</Text1>
            <br />
            <SlantedBackground>{t("home.hero.heroText2")}</SlantedBackground>
          </>
        }
        action={
          <Actions>
            <PrimaryButton href="#values">
              {t("home.hero.values")}
            </PrimaryButton>
            <SecondaryButton href="/contact-us" as="a">
              {t("home.hero.contactUs")}
            </SecondaryButton>
          </Actions>
        }
        illustration={
          <ImageContainer>
            <img src={imgIllustration} alt="" />
          </ImageContainer>
        }
        header={<MegaMenu isBgDark title={dataConfig.site.logoTrail} />}
      />

      {values && values.length > 0 && (
        <Values
          id="values"
          subheading={t("home.values.subheading")}
          heading={t("home.values.heading")}
          description={t("home.values.description")}
          data={dataConfig.values(t)}
        />
      )}

      {testimonials && testimonials.length > 0 && (
        <Testimonials
          id="testimonials"
          subheading={t("home.testimonials.subheading")}
          heading={t("home.testimonials.heading")}
          data={testimonials}
        />
      )}

      {/* <Solutions
        id="solutions"
        heading={
          <>
            <PrimaryText>{t("home.solutions.heading1")}</PrimaryText>{" "}
            {t("home.solutions.heading2")}
          </>
        }
        description={null}
        cards={dataConfig.solutions(t)}
        linkText={t("home.solutions.btnView")}
      /> */}

      <ContactUsForm
        subheading={t("contact.subheading")}
        formAction={null}
        heading={
          <>
            {t("contact.heading1")}{" "}
            <PrimaryText> {t("contact.heading2")}</PrimaryText>
            <wbr /> {t("contact.heading3")}.
          </>
        }
        description={t("contact.description")}
        submitButtonText={t("contact.buttonText")}
      />

      <Footer
        title={dataConfig.site.logoTrail}
        copyright={dataConfig.site.copyright}
        socialLinks={null}
        navLinks={dataConfig.footer.navLinks(t)}
      />
    </AnimationRevealPage>
  );
};
