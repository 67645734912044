// assets
import JmtTrackingImageSrc from "images/products/jmtTracking.png";

// hooks
import useLocales from "hooks/useLocales";

// helpers
import TwoColWithTextAndPreviewCard from "components/features/TwoColWithTextAndPreviewCard";

// sections
import BaseSolutionPage from "./BaseSolutionPage";
import Markdown from "components/markdown/Markdown";

export default () => {
  const { t, currentLang } = useLocales();

  const headingText = t("jmtTracking.title");

  return (
    <BaseSolutionPage
      headingText={headingText}
      category="erpSolutions.internal"
    >
      <TwoColWithTextAndPreviewCard
        textContent={<Markdown children={getContent(currentLang)} />}
        btnViewText={t("buttons.view")}
        card={{
          url: "https://tracking.jmt.vn/",
          imageSrc: JmtTrackingImageSrc,
        }}
      />
    </BaseSolutionPage>
  );
};

function getContent(currentLang) {
  if (currentLang?.value === "en") return enContent;
  return viContent;
}

const enContent = `
TBU
`;

const viContent = `
*   Chuẩn hoá việc chấm công cho nhân viên theo vị trí.
*   Giám sát vị trí thực tế của nhân viên (cả trong nhà và ngoài trời).
*   Số hóa các nghiệp vụ vận hành, tuần tra, kiểm soát an ninh.
*   Giám sát công việc, đảm bảo tuân thủ đúng quy trình.
*   Cập nhật và xử lý sự cố.
*   Giảm chi phí nhân sự giám sát, thẩm định.
`;
