import CustomizeIconImage from "images/customize-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";
import SupportIconImage from "images/support-icon.svg";

import { ReactComponent as ActivityIcon } from "feather-icons/dist/icons/activity.svg";
import { ReactComponent as BatteryIcon } from "feather-icons/dist/icons/battery.svg";
// import { ReactComponent as LockIcon } from "feather-icons/dist/icons/lock.svg";
import { ReactComponent as MonitorIcon } from "feather-icons/dist/icons/monitor.svg";
import { ReactComponent as TruckIcon } from "feather-icons/dist/icons/truck.svg";
// import { ReactComponent as UserIcon } from "feather-icons/dist/icons/user.svg";
import { ReactComponent as CameraIcon } from "feather-icons/dist/icons/camera.svg";
import { ReactComponent as WindIcon } from "feather-icons/dist/icons/wind.svg";

const site = {
  title: "JMT Technology",
  logoTrail: "Technology",
  copyright: (
    <>
      &copy; Copyright {new Date().getFullYear()}, JMT Technology JSC. All
      Rights Reserved.
    </>
  ),
};

const footer = {
  navLinks: (t) => [
    { href: "/", text: t("nav.home") },
    { href: "/about-us", text: t("nav.about") },
    { href: "/contact-us", text: t("nav.contact") },
    { href: "/privacy", text: t("nav.privacy") },
  ],
};

const forms = {
  formspree: "xeqwojdw",
};

const iotSolutions = (t) => [
  {
    title: t("home.solutions.data.tParking"),
    description: t("home.solutions.data.dParking"),
    shortDescription: t("home.solutions.data.sdParking"),
    url: "/solutions/jmt-parking",
    imageSrc: "",
    imageIcon: null,
  },
  {
    title: t("home.solutions.data.tIndoorNavigation"),
    description: t("home.solutions.data.dIndoorNavigation"),
    shortDescription: t("home.solutions.data.sdIndoorNavigation"),
    url: "/solutions/jmt-indoor-navigation",
    imageSrc: "",
    imageIcon: null,
  },
  {
    title: t("home.solutions.data.tCameraAi"),
    description: t("home.solutions.data.dCameraAi"),
    shortDescription: t("home.solutions.data.sdCameraAi"),
    url: "/solutions/jmt-camera-ai",
    imageSrc: "",
    imageIcon: CameraIcon,
  },
  {
    title: t("home.solutions.data.tEnviromentMonitoring"),
    description: t("home.solutions.data.dEnviromentMonitoring"),
    shortDescription: t("home.solutions.data.sdEnviromentMonitoring"),
    url: "/solutions/jmt-enviroment-monitoring",
    imageSrc: "",
    imageIcon: WindIcon,
  },
  {
    title: t("home.solutions.data.tLibrary"),
    description: t("home.solutions.data.dLibrary"),
    shortDescription: t("home.solutions.data.sdLibrary"),
    url: "/solutions/jmt-library",
    imageSrc: "",
    imageIcon: null,
  },
  {
    title: t("home.solutions.data.tMeeting"),
    description: t("home.solutions.data.dMeeting"),
    shortDescription: t("home.solutions.data.sdMeeting"),
    url: "/solutions/jmt-meeting",
    imageSrc: "",
    imageIcon: null,
  },
  {
    title: t("home.solutions.data.tAutomation"),
    description: t("home.solutions.data.dAutomation"),
    shortDescription: t("home.solutions.data.sdAutomation"),
    url: "/solutions/jmt-automation",
    imageSrc: "",
    imageIcon: null,
  },
  // {
  //   title: t("home.solutions.data.tAccessControl"),
  //   description: t("home.solutions.data.dAccessControl"),
  //   url: "/solutions/jmt-access-control",
  //   imageSrc: "",
  //   imageIcon: LockIcon,
  // },
];

const erpSolutions = (t) => ({
  business: [
    {
      title: t("home.solutions.data.tMobiSales"),
      description: t("home.solutions.data.dMobiSales"),
      shortDescription: t("home.solutions.data.sdMobiSales"),
      url: "/solutions/jmt-mobisales",
      imageSrc: "",
      imageIcon: null,
    },
    {
      title: t("home.solutions.data.tCustomerIdentify"),
      description: t("home.solutions.data.dCustomerIdentify"),
      shortDescription: t("home.solutions.data.sdCustomerIdentify"),
      url: "/solutions/jmt-customer-identify",
      imageSrc: "",
      imageIcon: null,
    },
  ],
  internal: [
    {
      title: t("home.solutions.data.tTracking"),
      description: t("home.solutions.data.dTracking"),
      shortDescription: t("home.solutions.data.sdTracking"),
      url: "/solutions/jmt-tracking",
      imageSrc: "",
      imageIcon: ActivityIcon,
    },
    {
      title: t("home.solutions.data.tWarehouse"),
      description: t("home.solutions.data.dWarehouse"),
      shortDescription: t("home.solutions.data.sdWarehouse"),
      url: "/solutions/jmt-warehouse",
      imageSrc: "",
      imageIcon: null,
    },
    // {
    //   title: t("home.solutions.data.tHr"),
    //   description: t("home.solutions.data.dHr"),
    //   url: "/solutions/jmt-hr",
    //   imageSrc: "",
    //   imageIcon: UserIcon,
    // },
  ],
  operational: [
    {
      title: t("home.solutions.data.tAssetManagement"),
      description: t("home.solutions.data.dAssetManagement"),
      shortDescription: t("home.solutions.data.sdAssetManagement"),
      url: "/solutions/jmt-asset-management",
      imageSrc: "",
      imageIcon: MonitorIcon,
    },
    {
      title: t("home.solutions.data.tVehicleTracking"),
      description: t("home.solutions.data.dVehicleTracking"),
      shortDescription: t("home.solutions.data.sdVehicleTracking"),
      url: "/solutions/jmt-vehicle-tracking",
      imageSrc: "",
      imageIcon: TruckIcon,
    },
    {
      title: t("home.solutions.data.tEnergy"),
      description: t("home.solutions.data.dEnergy"),
      shortDescription: t("home.solutions.data.sdEnergy"),
      url: "/solutions/jmt-energy",
      imageSrc: "",
      imageIcon: BatteryIcon,
    },
  ],
});

const values = (t) => [
  {
    imageSrc: ShieldIconImage,
    title: t("home.values.data.t1"),
    description: t("home.values.data.d1"),
  },
  {
    imageSrc: SimpleIconImage,
    title: t("home.values.data.t2"),
    description: t("home.values.data.d2"),
  },
  {
    imageSrc: FastIconImage,
    title: t("home.values.data.t3"),
    description: t("home.values.data.d3"),
  },
  {
    imageSrc: ReliableIconImage,
    title: t("home.values.data.t4"),
    description: t("home.values.data.d4"),
  },
  {
    imageSrc: CustomizeIconImage,
    title: t("home.values.data.t5"),
    description: t("home.values.data.d5"),
  },
  {
    imageSrc: SupportIconImage,
    title: t("home.values.data.t6"),
    description: t("home.values.data.d6"),
  },
];

const testimonials = (t) => [
  {
    imageSrc:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
    quote:
      "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia.",
    customerName: "Charlotte Hale",
    customerTitle: "CEO, Delos Inc.",
  },
  {
    imageSrc:
      "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
    quote:
      "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia.",
    customerName: "Adam Cuppy",
    customerTitle: "Founder, EventsNYC",
  },
  {
    imageSrc:
      "https://images.unsplash.com/photo-1580852300654-03c803a14e24?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4.25&w=512&h=512&q=80",
    quote:
      "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia.",
    customerName: "Steven Marcetti",
    customerTitle: "Event Manager, Brite",
  },
];

const mainConfig = {
  site,
  footer,
  erpSolutions,
  iotSolutions,
  values,
  testimonials,
  forms,
};

export default mainConfig;
