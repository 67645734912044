// hooks
import useLocales from "hooks/useLocales";

// helpers
import TwoColWithTextAndPreviewCard from "components/features/TwoColWithTextAndPreviewCard";

// sections
import BaseSolutionPage from "./BaseSolutionPage";
import Markdown from "components/markdown/Markdown";

export default () => {
  const { t, currentLang } = useLocales();

  const headingText = t("jmtAccessControl.title");

  return (
    <BaseSolutionPage headingText={headingText}>
      <TwoColWithTextAndPreviewCard
        textContent={<Markdown children={getContent(currentLang)} />}
        btnViewText={t("buttons.view")}
        card={null}
      />
    </BaseSolutionPage>
  );
};

function getContent(currentLang) {
  if (currentLang?.value === "en") return enContent;
  return viContent;
}

const enContent = `
TBU
`;

const viContent = `
*   Quản lý danh sách nhân viên, nghiệp vụ chấm công.
*   Kiểm soát vào/ra bằng khuôn mặt, vân tay, thẻ từ. Giới hạn phân quyền theo từng user, thời gian.
*   Đăng ký khách, đăng ký khuôn mặt qua ứng dụng và website.
*   Tích hợp kết quả chấm công với các hệ thống quản trị, tính lương.
*   Tích hợp liên động nhận dạng khuôn mặt với hệ thống Camera an ninh, phân tầng thang máy.
*   Quản lý thiết bị cấu hình thiết bị và thẻ.
`;
