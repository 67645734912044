export default function scrollToElement(selector, options) {
  if (!document || !selector) return;

  const element = document.querySelector(selector);
  if (!element) return;

  element.scrollIntoView();
  element.scrollIntoView(false);
  element.scrollIntoView({ block: "end" });
  element.scrollIntoView({
    behavior: "smooth",
    // block: "end",
    inline: "nearest",
    ...options,
  });
}
