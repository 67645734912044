import { useTranslation } from "react-i18next";

// components
import ThreeColLinkDetailsWithoutHeading from "components/cards/ThreeColLinkDetailsWithoutHeading";

// helpers
import dataConfig from "dataConfig";
import { useMemo } from "react";

export default () => {
  const { t } = useTranslation();

  const cards = useMemo(() => {
    return dataConfig.iotSolutions(t).map((solution) => ({
      title: solution.title,
      href: solution.url,
      description: solution.shortDescription,
    }));
  }, [t]);

  return <ThreeColLinkDetailsWithoutHeading cards={cards} />;
};
