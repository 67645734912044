import { useParams } from "react-router-dom";

import JmtAccessControlPage from "pages/solutions/JmtAccessControlPage";
import JmtAssetManagementPage from "pages/solutions/JmtAssetManagementPage";
import JmtCameraAiPage from "pages/solutions/JmtCameraAiPage";
import JmtEnergyPage from "pages/solutions/JmtEnergyPage";
import JmtEnvironmentMonitorPage from "pages/solutions/JmtEnvironmentMonitorPage";
import JmtHrPage from "pages/solutions/JmtHrPage";
import JmtTrackingPage from "pages/solutions/JmtTrackingPage";
import JmtVehicleTrackingPage from "pages/solutions/JmtVehicleTrackingPage";
import JmtIndoorNavigation from "./solutions/JmtIndoorNavigation";
import JmtLibrary from "./solutions/JmtLibrary";
import JmtParking from "./solutions/JmtParking";
import JmtMeeting from "./solutions/JmtMeeting";
import JmtAutomation from "./solutions/JmtAutomation";
import JmtMobiSales from "./solutions/JmtMobiSales";
import JmtCustomerIdentify from "./solutions/JmtCustomerIdentify";
import JmtWarehouse from "./solutions/JmtWarehouse";

export const solutions = {
  "jmt-parking": {
    component: JmtParking,
    url: "/solutions/jmt-parking",
  },
  "jmt-indoor-navigation": {
    component: JmtIndoorNavigation,
    url: "/solutions/jmt-indoor-navigation",
  },
  "jmt-camera-ai": {
    component: JmtCameraAiPage,
    url: "/solutions/jmt-camera-ai",
  },
  "jmt-enviroment-monitoring": {
    component: JmtEnvironmentMonitorPage,
    url: "/solutions/jmt-enviroment-monitoring",
  },
  "jmt-library": {
    component: JmtLibrary,
    url: "/solutions/jmt-library",
  },
  "jmt-meeting": {
    component: JmtMeeting,
    url: "/solutions/jmt-meeting",
  },
  "jmt-automation": {
    component: JmtAutomation,
    url: "/solutions/jmt-automation",
  },
  "jmt-mobisales": {
    component: JmtMobiSales,
    url: "/solutions/jmt-mobisales",
  },
  "jmt-customer-identify": {
    component: JmtCustomerIdentify,
    url: "/solutions/jmt-customer-identify",
  },
  "jmt-tracking": {
    component: JmtTrackingPage,
    url: "/solutions/jmt-tracking",
  },
  "jmt-warehouse": {
    component: JmtWarehouse,
    url: "/solutions/jmt-warehouse",
  },
  "jmt-asset-management": {
    component: JmtAssetManagementPage,
    url: "/solutions/jmt-asset-management",
  },
  "jmt-vehicle-tracking": {
    component: JmtVehicleTrackingPage,
    url: "/solutions/jmt-vehicle-tracking",
  },
  "jmt-energy": {
    component: JmtEnergyPage,
    url: "/solutions/jmt-energy",
  },
  "jmt-access-control": {
    component: JmtAccessControlPage,
    url: "/solutions/jmt-access-control",
  },
  "jmt-hr": {
    component: JmtHrPage,
    url: "/solutions/jmt-hr",
  },
};

export default () => {
  const { name } = useParams();

  try {
    let Component = solutions[name].component;

    if (Component) return <Component />;

    throw new Error("Component Not Found");
  } catch (e) {
    console.log(e);
    return <div>Error: Component Not Found</div>;
  }
};
