// hooks
import useLocales from "hooks/useLocales";

// helpers
import TwoColWithTextAndPreviewCard from "components/features/TwoColWithTextAndPreviewCard";

// sections
import BaseSolutionPage from "./BaseSolutionPage";
import Markdown from "components/markdown/Markdown";

export default () => {
  const { t, currentLang } = useLocales();

  const headingText = t("jmtEnergy.title");

  return (
    <BaseSolutionPage
      headingText={headingText}
      category="erpSolutions.operational"
    >
      <TwoColWithTextAndPreviewCard
        textContent={<Markdown children={getContent(currentLang)} />}
        btnViewText={t("buttons.view")}
        card={null}
      />
    </BaseSolutionPage>
  );
};

function getContent(currentLang) {
  if (currentLang?.value === "en") return enContent;
  return viContent;
}

const enContent = `
TBU
`;

const viContent = `
*   Giám sát lượng điện tiêu thụ theo thời gian thực.
*   Giám sát lượng điện tiêu thụ theo từng hệ thống / khu vực nhỏ.
*   So sánh, phân tích cấu thành chi phí điện theo thời gian, hệ thống, khu vực.
*   Cảnh báo khi phát sinh các bất thường về năng lượng tiêu thụ.
*   Hỗ trợ lập kế hoạch tiết kiệm điện theo tháng, năm.
*   Giảm chi phí điện, giảm chi phí nhân sự giám sát.
`;
